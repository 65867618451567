const RegExp = {
  Email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  Code: /^\d{6}$/,
  Phone: /^(\+81)\d{1,4}[ \-]?\d{1,4}[ \-]?\d{4}$/,
  Password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`=+])\S{8,99}$/,
  trim: /(^\s*|\s*$)/g,
  IntBigZero: /^[0-9]\d*$/
};

const Error = {
  EmailEmpty: "メールアドレスを入力してください。",
  ValidatingCodeEmpty: "パスコードを入力してください。",
  ValidatingFaild: "認証に失敗しました。",
  EmailInvalid: "メールアドレスまたはパスワードに誤りがあります。",
  EmailMisMatch: "入力されたメールアドレスは一致しません。",
  Email2Empty: "メールアドレス（確認用）を入力してください。",
  Email2Invalid: "メールアドレス（確認用）またはパスワードに誤りがあります。",
  PasswordEmpty: "パスワードを入力してください。",
  E0003: "スクリプト実行中にエラーが発生しました。IT本部にご連絡ください。",
  E0005: "POSデータと入金機データの明細が一致しません。赤枠部分の入金機データを訂正してください。訂正不能な場合には経理に連絡してください。",
  E0007: "入金データが取得できません。明細を手入力で追加してください。",
  E0008: "オフクレジットデータが取得できません。データの回復を待って後日対応してください。",
  E0015: "データハブから日報データが取得できません。しばらく経ってから再度お試しください。",
  E0032: "合計決済額と入力済みの合計決済額が一致しません。",
  // E0038: "POSデータが取得できないため、入力作業はできません。しばらく経ってからデータの更新をしてください。",
  E0039: "オフクレジットが発生しています。ブランド種別を入力してください。",
  E0040: "テナントクレジットのテナント種別が欠落しています。テナント種別を入力してください。",
  E0041: "テナントクレジットの明細が欠落しています。明細を手入力で追加してください。",
  E0042: "テナントのクレジットデータが取得できません。データの回復を待って後日対応してください。",
  E0043: "*のついた項目の入力は必須です",
  E0045: "レジNo.{0}は重複しています。レジNo.をご確認ください。",
  E0046: "レジNo.が存在しないデータがあります。レジNo.を入力してください。",
  E0047: "レジNo101~105はサービスカウンターのため、入力しないでください。",
  ReceiptError: "入金伝票が出力できません。IT本部にご連絡ください。"
};

const CognitoLoginUrl = {
  // stage
  // url: "https://ok-stg2.auth.ap-northeast-1.amazoncognito.com/login?client_id=lmjpp5e38ouslim4tau7imq6k&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://bmmzo9nkbf.execute-api.ap-northeast-1.amazonaws.com/stg/api/idToken"
  url: "https://okkc-adfs-stg.auth.ap-northeast-1.amazoncognito.com/oauth2/authorize?identity_provider=okkc-adfs-stg-idp&client_id=1s3qrnt3ev6qbc18bhia7incri&response_type=code&scope=email+openid+phone&redirect_uri=https://bmmzo9nkbf-vpce-075d1dca6683dc069.execute-api.ap-northeast-1.amazonaws.com/stg/api/idToken",
  logoutUrl: "https://okkc-adfs-stg.auth.ap-northeast-1.amazoncognito.com/logout?client_id=1s3qrnt3ev6qbc18bhia7incri&logout_uri=https://storeacc.stg.okc-sys.com"
}


const RouterString = {
  Login: "/login",
  LoginError: "/login_error",

  //Store
  Store: "/store",
  Report: "/store/report",
  Overview: "/store/overview",
  OffCredit: "/store/offcredit",
  TenantCredit: "/store/tenantcredit",
  Details: "/store/details",
  Confirm: "/store/confirm",

  //Accounting
  Accounting: "/accounting",
  ReportStatus: "/accounting/reportstatus",
  UnsubmittedStores: "/accounting/unsubmittedStores",
  UnauthorizedStores: "/accounting/unauthorizedStores",
  AuthorizedStores: "/accounting/authorizedStores",
  CompareBorrow: "/accounting/compareBorrow",
  DailyDetail: "/accounting/dailyDetail",

  // Master
  Master: "/master"

}

const localConstants = {
  StoreData: "StoreData",
  IdToken: "IdToken",
  RefershToken: "RefershToken",
  BusinessDay: "BusinessDay",
  StoreId: "StoreId",
  ExpiredTime: "ExpiredTime",
  Email: "Email",
  UserAccess: "UserAccess",
  StoreName: "StoreName",
  LastName: "LastName",
  FirstName: "FirstName",
}

const networkErr = {
  interceptionRequestErr: "interception request",
  ERR_INTERNET_DISCONNECTED: "Network Error",
  IdTokenExpiredMsg: "The incoming token has expired",
  networkErrorText: "ネットワークに接続できません。",
  noneErrorText: "不明なエラーが発生しました。"
}

export {
  RegExp,
  Error,
  CognitoLoginUrl,
  RouterString,
  localConstants,
  networkErr
};
