


import React, { useEffect, useState, Fragment } from "react";
import {
    Box,
    List,
    ListItem,
    Divider,
    Typography,
    Button,
    DialogActions
} from "@mui/material";
import { withStyles } from "@mui/styles";

import { RouterString } from "../../common/constants";
import { Link } from "react-router-dom";
import ListIcon from '@mui/icons-material/List';
import moment from "moment";
import SingleSureButton from "../dialog/SingleSureButton";
import CompleteDialog from "./CompleteDialog";
import { useNavigate } from "react-router-dom";
import { localConstants, Error } from "../../common/constants";
import { submitOverviewAPI } from "../../../network/api";
import { useMounted } from "../../../global/function/useMounted";
import BaseCircularProgress from "../basic/BaseCircularProgress";
import { fi } from "date-fns/locale";
import ReceiptForm from "./ReceiptForm";
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas'
import barcode from "../../common/barcode.json";
import { CognitoLoginUrl } from '../../../global/common/constants.js';

const ref = React.createRef();

// 画面スタイル
const styles = (theme) => ({
    root: {
        height: 'calc(100vh)',
        width: '18.75%',
        background: theme.palette.primary.main,
        flexShrink: 0,
        padding: 0,
        margin: 0,
        display: "flex",
        flexDirection: "column",
        overflowY: 'scroll'
    },
    menuLink: {
        textDecoration: "none",
        color: theme.palette.text.primary,
    },
    permanentDrawerListItem: {
        justifyContent: "left",
        padding: "0px",
        margin: "0px",
    },
    selectedDivider: {
        width: 4,
        background: "#EF7A00",
    },
    itemDivider: {
        width: 4,
        background: "transparent",
    },
    brandText: {
        fontSize: 14,
        fontWeight: 700,
        color: theme.palette.white,
    },
    primaryText: {
        fontSize: 14,
        fontWeight: 700,
        color: theme.palette.white,
    },
    dateContent: {
        height: '100%',
        textAlign: "left",
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '16px',
        color: '#162337',
        fontSize: '16px',
        fontWeight: '400'
    },
    inputComplete: {
        backgroundColor: '#00BDE6'
    },
    inputNotComplete: {
        backgroundColor: '#2C3849'
    },
    inputError: {
        backgroundColor: '#E60024'
    },
    sureButton: {
        fontSize: 16,
        marginBottom: 16,
        backgroundColor: "#EF7A00",
        "&:enabled:hover": {
            backgroundColor: "#EF7A00",
            color: "#fff",
        },
    },
    disabledButton: {
        fontSize: 16,
        marginBottom: 16,
        backgroundColor: "#CCCCCC",
        color: "#AAAAAA",
        "&:enabled:hover": {
            backgroundColor: "#CCCCCC",
            color: "#AAAAAA",
        },
    },
    actions: {
        width: 480,
        height: 96,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
});

// 左側メニュー画面入り口
function StoreMenu(props) {
    const {
        classes,
        selectedTab,
        storeData,
        setStoreData,
        submitDisabled,
        setSubmitDisabled,
        setIsCircularLoading,
        isCircularLoading
    } = props
    // メニュー項目リスト
    let menus = [
        {
            link: RouterString.Report,
            name: "日報一覧",
            selected: (selectedTab == RouterString.Report || selectedTab == "/store") ? true : false
        },
        {
            link: RouterString.Overview,
            name: "概要",
            selected: selectedTab == RouterString.Overview ? true : false
        },
        {
            link: RouterString.OffCredit,
            name: "オフクレジット",
            selected: selectedTab == RouterString.OffCredit ? true : false
        },
        {
            link: RouterString.TenantCredit,
            name: "テナントクレジット",
            selected: selectedTab == RouterString.TenantCredit ? true : false
        },
        {
            link: RouterString.Details,
            name: "内訳",
            selected: selectedTab == RouterString.Details ? true : false
        },
        {
            link: RouterString.Confirm,
            name: "最終確認",
            selected: selectedTab == RouterString.Confirm ? true : false
        },

    ]

    // 定数定義
    let [menuItems, setMenuItems] = useState(menus)
    const [dateTime, setDateTime] = useState()
    const [hideMenu, setHideMenu] = useState(true)
    const [isComplete, setIsComplete] = useState(false);
    const navigate = useNavigate()
    const isMounted = useMounted()
    const [detailsStatus, setDetailsStatus] = useState(false)
    const [confirmStatus, setConfirmStatus] = useState(false)

    const [showOffCreditStatus, setShowOffCreditStatus] = useState(false)
    const [OffCreditStatus, setOffCreditStatus] = useState(false)

    const [TenantCreditStatus, setTenantCreditStatus] = useState(false)
    const [showTenantCreditStatus, setShowTenantCreditStatus] = useState(false)

    function showCompleteDialog() {
        if (!submitDisabled) {
            setIsComplete(true)
        }
    }
    function logout() {
        setIsComplete(false)
        localStorage.clear()
        window.location.href = CognitoLoginUrl.logoutUrl
        // navigate("/")
    }
    function closeCompleteDialog() {
        setIsComplete(false)

    }
    function submitOverviewData() {
        setIsCircularLoading(true);
        let param = { storeId: localStorage.getItem(localConstants.StoreId), ...storeData }
        console.log(param)
        submitOverviewAPI(param).then((res) => {
            if (!isMounted()) return
            console.log(res)
            let resData = res.data
            if (res.status >= 400 && res.status < 500) {
                alert(Error.E0003);
            } else if (res.status == 200) {
                exportPDF()
                showCompleteDialog()
            }
            setIsCircularLoading(false)
        }).catch((err) => {
            if (!isMounted()) return
            setIsCircularLoading(false)
        })
    }
    function checkString(str) {
        for (var i = 0; i < str.length; i++) {
            let strCode = str.charCodeAt(i);
            if ((strCode > 65248) || (strCode == 12288)) {
                return false;
            }
        }
        return true;
    }
    function exportPDF() {
        let storeID = localStorage.getItem(localConstants.StoreId)
        let storeList = []
        var count = 0
        barcode.forEach((e) => {
            storeList.push(e.StoreCode)
        });
        storeList.forEach((e) => {
            if (e == storeID) {
                count++
            }
        });
        if (count > 1) {
            alert(Error.ReceiptError);
            return
        }
        for (let index = 0; index < barcode.length; index++) {
            if (barcode[index].StoreCode == storeID) {
                if (barcode[index].RegionalCode == null || barcode[index].RegionalCode == "" || barcode[index].StoreIdentificationCode == null || barcode[index].StoreIdentificationCode == "") {
                    alert(Error.ReceiptError);
                    return
                }
                if (!checkString(barcode[index].RegionalCode) || !checkString(barcode[index].StoreIdentificationCode)) {
                    alert(Error.ReceiptError);
                    return
                }
                const input = document.getElementById('exportPDF');
                html2canvas(input)
                    .then((canvas) => {
                        const imgData = canvas.toDataURL('image/png');
                        const pdf = new jsPDF();
                        const imgProps = pdf.getImageProperties(imgData);
                        const pdfWidth = pdf.internal.pageSize.getWidth();
                        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                        let nowTime = new Date().toLocaleString();
                        // 入金伝票_[店舗名]_[営業日]_<timestamp>
                        const pdfName = "入金伝票_" + localStorage.getItem(localConstants.StoreName) + "_" + dateTime + "_" + nowTime + ".pdf"
                        pdf.save(pdfName);
                    })
                return
            }
        }
        alert(Error.ReceiptError);

    }
    // メニュー初期化画面切替表示
    useEffect(() => {
        setDateTime(storeData.nyukinki?.reportData?.businessDay)
        let menuItemsCopy = [...menuItems]
        menuItemsCopy.map(item => {
            item.selected = false
        })

        switch (selectedTab) {
            case RouterString.Report:
            case "/store":
                menuItemsCopy[0].selected = true
                break
            case RouterString.Overview:
                menuItemsCopy[1].selected = true
                break
            case RouterString.OffCredit:
                menuItemsCopy[2].selected = true
                break
            case RouterString.TenantCredit:
                menuItemsCopy[3].selected = true
                break
            case RouterString.Details:
                menuItemsCopy[4].selected = true
                break
            case RouterString.Confirm:
                menuItemsCopy[5].selected = true
                break

        }

        setMenuItems(menuItemsCopy)
        if (selectedTab == RouterString.Report) {
            setHideMenu(true)
            setSubmitDisabled(true)
            setDetailsStatus(false)
            setConfirmStatus(false)
            setShowOffCreditStatus(false)
            setOffCreditStatus(false)
            setTenantCreditStatus(false)
            setShowTenantCreditStatus(false)
        } else {
            setHideMenu(false)
        }
        if (selectedTab == RouterString.Details) {
            setDetailsStatus(true)
        }
        if (selectedTab == RouterString.Confirm) {
            setConfirmStatus(true)
        }

        for (var i = 0; i < storeData.nyukinki?.overviewData?.overViewTableTwo.length; i++) {
            if (storeData?.nyukinki?.overviewData?.overViewTableTwo[i]?.offPayment?.rowsData?.length > 0) {
                if (storeData.nyukinki?.overviewData?.overViewTableTwo[i]?.offPayment?.totalSettlementAmount == 0 || storeData.nyukinki?.overviewData?.overViewTableTwo[i]?.offPayment?.totalSettlementAmount == null) {
                    setShowOffCreditStatus(false)
                } else {
                    setShowOffCreditStatus(true)
                    break
                }
            }
        }
        for (var i = 0; i < storeData.nyukinki?.overviewData?.overViewTableTwo.length; i++) {
            let tempTotalSettlementAmountEntered = 0
            storeData?.nyukinki?.overviewData?.overViewTableTwo[i]?.offPayment?.rowsData?.map((rowsDataItem, index) => {
                if (rowsDataItem.settlementAmount != null && rowsDataItem.settlementAmount != "" && rowsDataItem.leaseCategory != null && rowsDataItem.leaseCategory != "") {
                    tempTotalSettlementAmountEntered += parseInt(rowsDataItem.settlementAmount)
                }
            })
            if (storeData.nyukinki?.overviewData?.overViewTableTwo[i]?.offPayment?.totalSettlementAmount != tempTotalSettlementAmountEntered && Number(storeData.nyukinki?.overviewData?.overViewTableTwo[i]?.offPayment?.totalSettlementAmount) != 0) {
                setOffCreditStatus(false)
                break
            } else {
                setOffCreditStatus(true)
            }
        }
        for (var i = 0; i < storeData.nyukinki?.overviewData?.overViewTableTwo.length; i++) {
            if (storeData.nyukinki?.overviewData?.overViewTableTwo[i]?.creditCard?.totalSettlementAmount == 0 || storeData.nyukinki?.overviewData?.overViewTableTwo[i]?.creditCard?.totalSettlementAmount == null) {
                setShowTenantCreditStatus(false)
            } else {
                setShowTenantCreditStatus(true)
                break
            }
        }
        for (var i = 0; i < storeData.nyukinki?.overviewData?.overViewTableTwo.length; i++) {
            let tempTotalSettlementAmountEntered = 0
            storeData?.nyukinki?.overviewData?.overViewTableTwo[i]?.creditCard?.rowsData?.map((rowsDataItem, index) => {
                if (rowsDataItem.settlementAmount != null && rowsDataItem.settlementAmount != "" && rowsDataItem.leaseCategory != null && rowsDataItem.leaseCategory != "") {
                    tempTotalSettlementAmountEntered += parseInt(rowsDataItem.settlementAmount)
                }
            })
            if (storeData.nyukinki?.overviewData?.overViewTableTwo[i]?.creditCard?.totalSettlementAmount != tempTotalSettlementAmountEntered && Number(storeData.nyukinki?.overviewData?.overViewTableTwo[i]?.creditCard?.totalSettlementAmount) != 0) {
                setTenantCreditStatus(false)
                break
            } else {
                setTenantCreditStatus(true)
            }
        }


    }, [selectedTab, storeData])
    useEffect(() => {
        if (detailsStatus && confirmStatus) {
            setSubmitDisabled(false)
        } else {
            setSubmitDisabled(true)
        }
    }, [detailsStatus, confirmStatus, OffCreditStatus, TenantCreditStatus])
    useEffect(() => {
        if (submitDisabled) {
            setDetailsStatus(false)
            setConfirmStatus(false)
        }
    }, [submitDisabled])
    // 画面表示
    return (
        !hideMenu ? <Box className={classes.root}>
            <div id="exportPDF" crossOrigin="anonymous" style={{ position: "absolute", zIndex: -1000 }}>
                <ReceiptForm storeData={storeData}></ReceiptForm>
            </div>
            <div style={{ backgroundColor: "#F4F4F4", position: "absolute", zIndex: -999, height: "443px", width: "808px" }}></div>
            <div style={{ display: "flex", marginLeft: 16, marginTop: 12, alignItems: "center" }}>
                <div>
                    <img
                        src={`${process.env.PUBLIC_URL + "/images/ok_logo.svg"}`}
                        style={{
                            width: "48px",
                            height: "24px",
                        }}
                    />
                </div>
                <Typography
                    variant="body1"
                    sx={{
                        color: "#FFFFFF",
                        marginLeft: "16px",
                        fontWeight: 700,
                        fontSize: "14px"
                    }}
                >
                    レジ日報
                </Typography>
            </div>
            <div style={{
                marginTop: 12,
            }} />
            <div>
                <div style={{ backgroundColor: "#ffffff", margin: "16px", borderRadius: '4px' }}>
                    <div className={classes.dateContent}>
                        {moment(dateTime).format("YYYY/MM/DD")}
                    </div>
                </div>
            </div>

            <div style={{
                borderBottom: "1px solid #2C3849",
            }} />
            <List>
                {
                    menuItems.map((element, index) => (
                        index == 0 ?
                            <Link
                                to={element.link}
                                key={element.link}
                                className={classes.menuLink}
                            >
                                <div style={{ display: "flex", alignItems: "center", height: "52px" }}>
                                    <Divider
                                        orientation="vertical"
                                        classes={
                                            element.selected
                                                ? { root: classes.selectedDivider }
                                                : { root: classes.itemDivider }
                                        }
                                        style={{ width: 4, height: "52px" }}
                                    />
                                    <ListIcon style={{ color: "#FFFFFF", width: 24, height: 24, marginLeft: 16 }} />
                                    <Typography
                                        sx={{
                                            color: "#FFFFFF",
                                            marginLeft: "8px",
                                            fontWeight: 700,
                                            fontSize: "14px"
                                        }}
                                    >
                                        {element.name}
                                    </Typography>

                                </div>
                                <div style={{
                                    borderBottom: "1px solid #2C3849",
                                }} />
                            </Link> :
                            index == 1 ? <Link
                                to={element.link}
                                key={element.link}
                                className={classes.menuLink}
                            >
                                <div style={{ display: "flex", alignItems: "center", height: "52px" }}>
                                    <Divider
                                        orientation="vertical"
                                        classes={
                                            element.selected
                                                ? { root: classes.selectedDivider }
                                                : { root: classes.itemDivider }
                                        }
                                        style={{ width: 4, height: "52px" }}
                                    />
                                    <Typography
                                        sx={{
                                            color: "#FFFFFF",
                                            fontWeight: 700,
                                            fontSize: "14px",
                                            marginLeft: "16px"
                                        }}
                                    >
                                        {element.name}
                                    </Typography>
                                    <div style={{
                                        width: '100%',
                                        flex: 1
                                    }}>
                                        {storeData?.nyukinki?.reportData?.errMsg?.length > 0 && <Box sx={{
                                            borderRadius: '50px',
                                        }}
                                            className={classes.inputError}
                                            style={{
                                                float: "right", marginRight: 8
                                            }}>
                                            <Typography
                                                style={{
                                                    marginRight: "8px",
                                                    marginLeft: "8px",
                                                    marginTop: "4px",
                                                    marginBottom: "4px",
                                                    fontSize: "12px",
                                                    fontWeight: 700,
                                                    color: "#FFFFFF",
                                                }}
                                                align="center"
                                            >
                                                {"エラー"}
                                            </Typography>
                                        </Box>}
                                    </div>
                                </div>
                                <div style={{
                                    borderBottom: "1px solid #2C3849",
                                }} />
                                <div style={{ display: "flex", alignItems: "center", height: "52px" }}>
                                    <Divider
                                        orientation="vertical"
                                        classes={
                                            element.selected
                                                ? { root: classes.selectedDivider }
                                                : { root: classes.itemDivider }
                                        }
                                        style={{ width: 4, height: "52px" }}
                                    />
                                    <Typography
                                        sx={{
                                            color: "#FFFFFF",
                                            marginLeft: "8px",
                                            fontWeight: 700,
                                            fontSize: "14px",
                                            marginLeft: "32px"
                                        }}
                                    >
                                        入金調整額
                                    </Typography>
                                    {storeData.nyukinki?.overviewData?.overViewTableOne?.cashAdjustment &&
                                        storeData.nyukinki?.overviewData?.overViewTableOne?.cashAdjustment != 0 && storeData.nyukinki?.overviewData?.overViewTableOne?.adjustmentDate != null && <div style={{
                                            width: '100%',
                                            flex: 1
                                        }}>
                                            <Box sx={{
                                                borderRadius: '50px',
                                            }}
                                                className={classes.inputComplete}
                                                style={{
                                                    float: "right", marginRight: 8
                                                }}>
                                                <Typography
                                                    style={{
                                                        marginRight: "8px",
                                                        marginLeft: "8px",
                                                        marginTop: "4px",
                                                        marginBottom: "4px",
                                                        fontSize: "12px",
                                                        fontWeight: 700,
                                                        color: "#FFFFFF",
                                                    }}
                                                    align="center"
                                                >
                                                    {"入力済"}
                                                </Typography>
                                            </Box>
                                        </div>}
                                </div>
                                <div style={{
                                    borderBottom: "1px solid #2C3849",
                                }} />
                                <div style={{ display: "flex", alignItems: "center", height: "52px" }}>
                                    <Divider
                                        orientation="vertical"
                                        classes={
                                            element.selected
                                                ? { root: classes.selectedDivider }
                                                : { root: classes.itemDivider }
                                        }
                                        style={{ width: 4, height: "52px" }}
                                    />
                                    <Typography
                                        sx={{
                                            color: "#FFFFFF",
                                            marginLeft: "8px",
                                            fontWeight: 700,
                                            fontSize: "14px",
                                            marginLeft: "32px"
                                        }}
                                    >
                                        元金変更
                                    </Typography>
                                    {storeData.nyukinki?.overviewData?.overViewTableOne?.principalChange &&
                                        storeData.nyukinki?.overviewData?.overViewTableOne?.principalChange != 0 && <div style={{
                                            width: '100%',
                                            flex: 1
                                        }}>
                                            <Box sx={{
                                                borderRadius: '50px',
                                            }}
                                                className={classes.inputComplete}
                                                style={{
                                                    float: "right", marginRight: 8
                                                }}>
                                                <Typography
                                                    style={{
                                                        marginRight: "8px",
                                                        marginLeft: "8px",
                                                        marginTop: "4px",
                                                        marginBottom: "4px",
                                                        fontSize: "12px",
                                                        fontWeight: 700,
                                                        color: "#FFFFFF",
                                                    }}
                                                    align="center"
                                                >
                                                    {"入力済"}
                                                </Typography>
                                            </Box>
                                        </div>}
                                </div>
                                <div style={{
                                    borderBottom: "1px solid #2C3849",
                                }} />
                            </Link> : index == 2 ? <Link
                                to={element.link}
                                key={element.link}
                                className={classes.menuLink}
                            >
                                <div style={{ display: "flex", alignItems: "center", height: "52px" }}>
                                    <Divider
                                        orientation="vertical"
                                        classes={
                                            element.selected
                                                ? { root: classes.selectedDivider }
                                                : { root: classes.itemDivider }
                                        }
                                        style={{ width: 4, height: "52px" }}
                                    />
                                    <Typography
                                        sx={{
                                            color: "#FFFFFF",
                                            fontWeight: 700,
                                            fontSize: "14px",
                                            marginLeft: "16px"
                                        }}
                                    >
                                        {element.name}
                                    </Typography>
                                    {showOffCreditStatus && <div style={{
                                        width: '100%',
                                        flex: 1
                                    }}>
                                        <Box sx={{
                                            borderRadius: '50px',
                                        }}
                                            className={OffCreditStatus ? classes.inputComplete : classes.inputNotComplete}
                                            style={{
                                                float: "right", marginRight: 8
                                            }}>
                                            <Typography
                                                style={{
                                                    marginRight: "8px",
                                                    marginLeft: "8px",
                                                    marginTop: "4px",
                                                    marginBottom: "4px",
                                                    fontSize: "12px",
                                                    fontWeight: 700,
                                                    color: "#FFFFFF",
                                                }}
                                                align="center"
                                            >
                                                {OffCreditStatus ? "入力済" : "未入力"}
                                            </Typography>
                                        </Box>
                                    </div>}
                                </div>
                                <div style={{
                                    borderBottom: "1px solid #2C3849",
                                }} />
                            </Link> : index == 3 ? <Link
                                to={element.link}
                                key={element.link}
                                className={classes.menuLink}
                            >
                                <div style={{ display: "flex", alignItems: "center", height: "52px" }}>
                                    <Divider
                                        orientation="vertical"
                                        classes={
                                            element.selected
                                                ? { root: classes.selectedDivider }
                                                : { root: classes.itemDivider }
                                        }
                                        style={{ width: 4, height: "52px" }}
                                    />
                                    <Typography
                                        sx={{
                                            color: "#FFFFFF",
                                            fontWeight: 700,
                                            fontSize: "14px",
                                            marginLeft: "16px"
                                        }}
                                    >
                                        {element.name}
                                    </Typography>
                                    {showTenantCreditStatus && <div style={{
                                        width: '100%',
                                        flex: 1
                                    }}>
                                        <Box sx={{
                                            borderRadius: '50px',
                                        }}
                                            className={TenantCreditStatus ? classes.inputComplete : classes.inputNotComplete}

                                            style={{
                                                float: "right", marginRight: 8
                                            }}>
                                            <Typography
                                                style={{
                                                    marginRight: "8px",
                                                    marginLeft: "8px",
                                                    marginTop: "4px",
                                                    marginBottom: "4px",
                                                    fontSize: "12px",
                                                    fontWeight: 700,
                                                    color: "#FFFFFF",
                                                }}
                                                align="center"
                                            >
                                                {TenantCreditStatus ? "入力済" : "未入力"}
                                            </Typography>
                                        </Box>
                                    </div>}
                                </div>
                                <div style={{
                                    borderBottom: "1px solid #2C3849",
                                }} />
                            </Link> : index == 4 ? <Link
                                to={element.link}
                                key={element.link}
                                className={classes.menuLink}
                            >
                                <div style={{ display: "flex", alignItems: "center", height: "52px" }}>
                                    <Divider
                                        orientation="vertical"
                                        classes={
                                            element.selected
                                                ? { root: classes.selectedDivider }
                                                : { root: classes.itemDivider }
                                        }
                                        style={{ width: 4, height: "52px" }}
                                    />
                                    <Typography
                                        sx={{
                                            color: "#FFFFFF",
                                            fontWeight: 700,
                                            fontSize: "14px",
                                            marginLeft: "16px"
                                        }}
                                    >
                                        {element.name}
                                    </Typography>
                                    <div style={{
                                        width: '100%',
                                        flex: 1
                                    }}>
                                        <Box sx={{
                                            borderRadius: '50px',
                                        }}
                                            className={detailsStatus ? classes.inputComplete : classes.inputNotComplete}
                                            style={{
                                                float: "right", marginRight: 8
                                            }}>
                                            <Typography
                                                style={{
                                                    marginRight: "8px",
                                                    marginLeft: "8px",
                                                    marginTop: "4px",
                                                    marginBottom: "4px",
                                                    fontSize: "12px",
                                                    fontWeight: 700,
                                                    color: "#FFFFFF",
                                                }}
                                                align="center"
                                            >
                                                {detailsStatus ? "確認済" : "未確認"}
                                            </Typography>
                                        </Box>
                                    </div>
                                </div>
                                <div style={{
                                    borderBottom: "1px solid #2C3849",
                                }} />
                            </Link> : index == 5 ? <Link
                                to={element.link}
                                key={element.link}
                                className={classes.menuLink}
                            >
                                <div style={{ display: "flex", alignItems: "center", height: "52px" }}>
                                    <Divider
                                        orientation="vertical"
                                        classes={
                                            element.selected
                                                ? { root: classes.selectedDivider }
                                                : { root: classes.itemDivider }
                                        }
                                        style={{ width: 4, height: "52px" }}
                                    />
                                    <Typography
                                        sx={{
                                            color: "#FFFFFF",
                                            fontWeight: 700,
                                            fontSize: "14px",
                                            marginLeft: "16px"
                                        }}
                                    >
                                        {element.name}
                                    </Typography>
                                    <div style={{
                                        width: '100%',
                                        flex: 1
                                    }}>
                                        <Box sx={{
                                            borderRadius: '50px',
                                        }}
                                            className={confirmStatus ? classes.inputComplete : classes.inputNotComplete}
                                            style={{
                                                float: "right", marginRight: 8
                                            }}>
                                            <Typography
                                                style={{
                                                    marginRight: "8px",
                                                    marginLeft: "8px",
                                                    marginTop: "4px",
                                                    marginBottom: "4px",
                                                    fontSize: "12px",
                                                    fontWeight: 700,
                                                    color: "#FFFFFF",
                                                }}
                                                align="center"
                                            >
                                                {confirmStatus ? "確認済" : "未確認"}
                                            </Typography>
                                        </Box>
                                    </div>
                                </div>
                                <div style={{
                                    borderBottom: "1px solid #2C3849",
                                }} />
                            </Link> : <Link>
                                <div></div>
                            </Link>
                    ))
                }
            </List>
            {storeData.nyukinki?.reportData?.approvalStatus != "2" &&
                <div style={{
                    flex: 1,
                    display: "flex", flexDirection: "column", justifyContent: "flex-end"
                }} >

                    <div style={{
                        borderBottom: "1px solid #2C3849", marginBottom: "16px",
                    }} />

                    <div style={{ marginLeft: 16, marginRight: 16 }}>
                        <SingleSureButton
                            text="提出"
                            classes={{
                                root: submitDisabled || isCircularLoading ? classes.disabledButton : classes.sureButton
                            }}
                            onClick={(e) => {
                                if (!submitDisabled) {
                                    submitOverviewData()
                                }
                            }}
                        />
                    </div>

                </div>}
            {storeData.nyukinki?.reportData?.approvalStatus == "1" || storeData.nyukinki?.reportData?.approvalStatus == "2" ?
                <div style={{
                    flex: storeData.nyukinki?.reportData?.approvalStatus == "2" ? 1 : 0,
                    display: "flex", flexDirection: "column", justifyContent: "flex-end"
                }} >
                    {storeData.nyukinki?.reportData?.approvalStatus == "2" && <div style={{
                        borderBottom: "1px solid #2C3849", marginBottom: "16px",
                    }} />}


                    <div style={{ marginLeft: 16, marginRight: 16 }}>
                        <SingleSureButton
                            text="入金伝票の出力"
                            classes={{
                                root: classes.sureButton
                            }}
                            onClick={(e) => {
                                exportPDF()
                            }}
                        />
                        {/* <Pdf targetRef={ref} filename="入金伝票.pdf" scale={1}>
                            {({ toPdf }) => <SingleSureButton
                                text="入金伝票の出力"
                                classes={{
                                    root: classes.sureButton
                                }}
                                onClick={toPdf}
                            />}
                        </Pdf> */}
                    </div>

                </div> : null}
            <CompleteDialog dialogVisible={isComplete} closeDialog={closeCompleteDialog} logout={logout} />
        </Box > : <Box className={classes.root}>
            <div style={{ display: "flex", marginLeft: 16, marginTop: 12, alignItems: "center" }}>
                <div>
                    <img
                        src={`${process.env.PUBLIC_URL + "/images/ok_logo.svg"}`}
                        style={{
                            width: "48px",
                            height: "24px",
                        }}
                    />
                </div>
                <Typography
                    variant="body1"
                    sx={{
                        color: "#FFFFFF",
                        marginLeft: "16px",
                        fontWeight: 700,
                        fontSize: "14px"
                    }}
                >
                    レジ日報
                </Typography>
            </div>
            <div style={{
                marginTop: 12,
            }} />
            <div style={{
                borderBottom: "1px solid #2C3849",
            }} />
            <List>
                <Link
                    to={menuItems[0].link}
                    key={menuItems[0].link}
                    className={classes.menuLink}
                >
                    <div style={{ display: "flex", alignItems: "center", height: "52px" }}>
                        <Divider
                            orientation="vertical"
                            classes={
                                menuItems[0].selected
                                    ? { root: classes.selectedDivider }
                                    : { root: classes.itemDivider }
                            }
                            style={{ width: 4, height: "52px" }}
                        />
                        <ListIcon style={{ color: "#FFFFFF", width: 24, height: 24, marginLeft: 16 }} />
                        <Typography
                            sx={{
                                color: "#FFFFFF",
                                marginLeft: "8px",
                                fontWeight: 700,
                                fontSize: "14px"
                            }}
                        >
                            {menuItems[0].name}
                        </Typography>

                    </div>
                    <div style={{
                        borderBottom: "1px solid #2C3849",
                    }} />
                </Link>
            </List>
        </Box >
    )
}

// 画面エクスポート
export default withStyles(styles, { withTheme: true })(StoreMenu);