import React, { useEffect, useState, Fragment } from "react";
import {
    Box,
    Typography, CircularProgress, Stack, Backdrop
} from "@mui/material";
import { withStyles } from "@mui/styles";
import MessageFunction from "../mountedWindow/MessageFunction";
import { onEventListener, offEventListener, customEventName } from "../../global/function/customEvent";

import { useMounted } from "../../global/function/useMounted";


import StoreRouters from "../../routes/StoreRouters";
import StoreMenu from "../../global/component/pages/StoreMenu";
import Settings from '@mui/icons-material/Settings';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowRight from '@mui/icons-material/ArrowRight';
import { useNavigate } from "react-router-dom";
import { RouterString } from "../../global/common/constants";
import { localConstants } from "../../global/common/constants";
import { objectToString, stringToObejct } from "../../global/function/JsonUtil"
import util from "../../global/common/util"
import ReceiptForm from "../../global/component/pages/ReceiptForm";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { CognitoLoginUrl } from "../../global/common/constants";

const styles = (theme) => ({

});

// トップ画面入り口
function Store(props) {

    // 定数定義
    const {
        classes,
    } = props
    const isMounted = useMounted()
    const [storeData, setStoreData] = useState({})
    const [selectedTab, setSelectedTab] = useState("/store")
    const [messageOpen, setMessageOpen] = useState(false)
    const [submitDisabled, setSubmitDisabled] = useState(true)
    const [message, setMessage] = useState("")
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isCircularLoading, setIsCircularLoading] = useState(false);
    const open = Boolean(anchorEl);
    const navigate = useNavigate()
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const logout = () => {
        localStorage.clear()
        setAnchorEl(null);
        window.location.href = CognitoLoginUrl.logoutUrl
        // navigate("/")
    };
    const handleCloseSnackBar = () => {
        setMessageOpen(false)
    }

    // 画面初期化処理
    useEffect(() => {
        console.log(storeData)
        if (util.getUrlParam("expiredTime")) {
            localStorage.setItem(localConstants.ExpiredTime, util.getUrlParam("expiredTime"))
            localStorage.setItem(localConstants.Email, util.getUrlParam("email"))
            localStorage.setItem(localConstants.UserAccess, util.getUrlParam("userAccess"))
            localStorage.setItem(localConstants.StoreId, util.getUrlParam("storeCode"))
            localStorage.setItem(localConstants.StoreName, util.getUrlParam("storeName"))
            localStorage.setItem(localConstants.LastName, util.getUrlParam("lastName"))
            localStorage.setItem(localConstants.FirstName, util.getUrlParam("firstName"))
            navigate(RouterString.Store)
        }
        if (!localStorage.getItem(localConstants.ExpiredTime)) {
            navigate(RouterString.Login)
            return
        }
        if (!localStorage.getItem(localConstants.UserAccess)) {
            navigate(RouterString.Login)
            return
        }
        if (parseInt(localStorage.getItem(localConstants.ExpiredTime)) < parseInt(new Date().getTime() / 1000)) {
            navigate(RouterString.Login)
            return
        }
        if (parseInt(localStorage.getItem(localConstants.UserAccess)) != 0) {
            navigate(RouterString.Login)
            return
        }
        // else if (parseInt(localStorage.getItem(localConstants.UserAccess)) == 2) {
        //     navigate(RouterString.Master)
        //     return
        // }
        if (storeData.nyukinki?.reportData?.businessDay) {
            localStorage.setItem(storeData.nyukinki.reportData?.businessDay + "-storeId=" + localStorage.getItem(localConstants.StoreId), objectToString(storeData))
        }
        const showMessage = ({ detail }) => {
            setMessage(detail.message)
            setMessageOpen(true)
        }
        onEventListener(customEventName.GlobalMessageTip, showMessage)
        return () => {
            offEventListener(customEventName.GlobalMessageTip, showMessage)
        }
    }, [storeData])


    // 画面表示
    return (
        <Box style={{
            height: '100vh', position: 'relative'
        }}>
            {isCircularLoading && <Backdrop
                sx={{ color: '#000', zIndex: 10001, }}
                open={isCircularLoading}
            >
                <Stack
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 10000,
                        color: "#fff"
                    }}>

                    <CircularProgress color='orange' size="4rem" />
                    <Typography style={{ fontSize: '20px' }} >
                        処理中です
                    </Typography>
                    <Typography style={{ fontSize: '20px' }} >
                        しばらくお待ちください
                    </Typography>
                </Stack>
            </Backdrop>}

            {/* <ReceiptForm storeData={storeData}></ReceiptForm> */}
            <Box sx={{
                display: 'flex'
            }}>

                {/* 左側メニュー表示 */}
                <StoreMenu
                    selectedTab={selectedTab}
                    storeData={storeData}
                    setStoreData={setStoreData}
                    submitDisabled={submitDisabled}
                    setSubmitDisabled={setSubmitDisabled}
                    setIsCircularLoading={setIsCircularLoading}
                    isCircularLoading={isCircularLoading}
                />
                <div style={{
                    display: "absolute",
                    width: "100%",
                }}>

                    {/* タイトルバー */}
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "right",
                        width: "100%",
                        height: "48px",
                        backgroundColor: "#FFFFFF"

                    }}>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                color: "#162337",
                                marginRight: "16px"
                            }}
                        >
                            {localStorage.getItem(localConstants.StoreName)}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                color: "#162337",
                                marginRight: "24px"
                            }}
                        >
                            {localStorage.getItem(localConstants.LastName) + " " + localStorage.getItem(localConstants.FirstName) + "さん"}
                        </Typography>
                        <Settings style={{ color: "#162337", width: 20, height: 20, marginRight: "24px", cursor: "pointer" }} onClick={handleClick} />
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            autoFocus={false}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={logout}>
                                <ArrowRight style={{ color: "#162337", width: 16, height: 16, marginRight: "8px", marginLeft: "8px" }} />
                                <Typography style={{
                                    color: "#162337", fontWeight: '400',
                                    fontSize: '14px', marginRight: "8px"
                                }} >
                                    ログアウト
                                </Typography>
                            </MenuItem>
                        </Menu>
                    </div>

                    {/* 選択したメニューに従い右側メイン画面表示切替 */}
                    <StoreRouters
                        setSelectedTab={setSelectedTab}
                        storeData={storeData}
                        setStoreData={setStoreData}
                        submitDisabled={submitDisabled}
                        setSubmitDisabled={setSubmitDisabled}
                        setIsCircularLoading={setIsCircularLoading}
                    />
                </div>

            </Box>

            {/* グローバルメッセージ処理 */}
            <MessageFunction
                open={messageOpen}
                message={message}
                handleCloseSnackBar={handleCloseSnackBar}
            />
        </Box >
    )
}

// 画面エクスポート
export default withStyles(styles, { withTheme: true })(Store);