import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { withStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import NumberFormat from "react-number-format";
import { authorizeAPI } from "../../../network/api";
import Alert from "@mui/material/Alert";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import {


  accountingCompareDataAPI
} from "../../../network/api";
import { useMounted } from "../../../global/function/useMounted";
import BaseCircularProgress from "../../../global/component/basic/BaseCircularProgress";
import { localConstants, Error } from "../../../global/common/constants";
import BasicTextfield from "../../../global/component/basic/BasicTextfield";
import NumberFormatCustom from "../../../global/component/basic/NumberFormatCustom";

// 画面レイアウトスタイル
const styles = (theme) => ({
  alert: {
    backgroundColor: "#FACCD3",
    color: "#E60024",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "16px",
    margin: "0 24px",
  },
  headRow: {
    borderRight: "1px solid #CCCCCC",
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    color: "#FFFFFF",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "150%",
    padding: "8px",
  },
  cell: {
    width: "140px",
    borderRight: "1px solid #CCCCCC",
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    color: "#162337",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    padding: "8px",
  },
  contentFont: {
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    color: "#002239",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "150%",
  },
  inputContent: {
    textAlign: "right",
    paddingTop: "8px",
    paddingBottom: "8px",
    color: "#162337",
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "400",
    ineHeight: "150%",
  },
  inputContentEx: {
    textAlign: "right",
    paddingTop: "8px",
    paddingBottom: "8px",
    color: "red",
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "400",
    ineHeight: "150%",
  },
  rightButton: {
    float: "right",
  },
  authoirzeButton: {
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    color: "#FFFFFF",
    fontSize: "16px",
    fontWeight: "700",
    height: "48px",
    marginBottom: "20px",
  },
  revokeAuthorizeButton: {
    height: "48px",
    width: "208px",
    margin: "16px",
    fontSize: "16px",
    fontWeight: "700",
    color: "#FFFFFF",
    backgroundColor: "#162337",
    "&:enabled:hover": {
      backgroundColor: "#162337",
      color: "#fff",
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#162337",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "16px",
    backgroundColor: "#FFFFFF",
  },
}));

//　画面入り口
function CompareBorrow(props) {
  const { classes, setSelectedTab,
    accountingData,
    setAccountingData } = props;

  // 画面初期化データ取得
  useEffect(() => {
    if (!accountingData?.storeId) {
      window.history.back()
      return
    }
    if (!accountingData.debits.length > 0) {
      getAccountingCompareData()
    }

  }, []);

  const [sumDebits, setSumDebits] = useState(0);
  const [sumLenders, setSumLenders] = useState(0);
  const [cashAdjustment, setCashAdjustment] = useState(0);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useMounted();

  const toDetail = () => {
    navigate("/accounting/dailyDetail");
  };
  //Approval Button
  const authorizeReport = () => {
    setIsLoading(true);
    let param = {
      businessDay: localStorage.getItem(localConstants.BusinessDay),
      storeId: accountingData?.storeId,
      approvalStatus: accountingData?.approvalStatus === "1" ? "2" : "1",
      totalDebit: sumDebits,
      totalCredit: sumLenders,
      debits: accountingData?.debits,
      lenders: accountingData?.lenders,
      dispensingCredit: accountingData?.dispensingCredit,
      mail: localStorage.getItem(localConstants.Email)
    }
    authorizeAPI(param).then((res) => {
      if (!isMounted()) return
      let resData = res.data
      if (res.status >= 400 && res.status < 500) {
        alert(Error.E0003);
      } else if (res.status === 200) {
        window.history.back()
      }
      setIsLoading(false)
    }).catch((err) => {
      setIsLoading(false)
      if (!isMounted()) return
    })
  }

  const getAccountingCompareData = () => {
    setIsLoading(true)
    let param = {
      businessDay: localStorage.getItem(localConstants.BusinessDay),
      storeId: accountingData?.storeId,
      approvalStatus: accountingData?.approvalStatus,
      totalDebit: 0,
      totalCredit: 0,
      mail: localStorage.getItem(localConstants.Email)
    }
    accountingCompareDataAPI(param).then((res) => {
      if (!isMounted()) return
      let resData = res.data
      if (res.status >= 400 && res.status < 500) {
        alert(Error.E0003);
      } else if (res.status === 200) {
        let tmpSalesRate = 0
        resData?.lenders?.forEach((tmp) => {
          if (tmp.screenDisplay === "1") {
            if (tmp.key == "売上高（標準税率）") {
              tmpSalesRate = tmp.shiwakeValue
            }
          }
        })
        setAccountingData({
          ...accountingData,
          debits: resData?.debits,
          lenders: resData?.lenders,
          dispensingCredit: resData?.dispensingCredit ? resData?.dispensingCredit : 0,
          tempSalesRate: tmpSalesRate
        })
      }
      setIsLoading(false)
    }).catch((err) => {
      setIsLoading(false)
      if (!isMounted()) return
    })

  };

  useEffect(() => {
    let tempSumDebits = 0
    let tempDisplayFlg = false
    let tempDispensingAccounts = 0
    accountingData?.debits?.forEach((row) => {
      if (row.screenDisplay === "1") {
        tempSumDebits += row.shiwakeValue
        if (row.key === "売掛金　調剤保険請求") {
          tempDisplayFlg = true
          tempDispensingAccounts = Number(row.shiwakeValue == "00" ? 0 : row.shiwakeValue)
        }
      }

    })
    setSumDebits(tempSumDebits)
    let tempSumLenders = 0
    accountingData?.lenders?.forEach((tmp) => {
      if (tmp.screenDisplay === "1") {
        tempSumLenders += tmp.shiwakeValue
        if (tmp.key == "売上高（標準税率）" && tempDisplayFlg) {
          tempSumLenders += tempDispensingAccounts
        }
      }
    })

    setSumLenders(tempSumLenders)
    setCashAdjustment(tempSumDebits - tempSumLenders)
  }, [accountingData]);

  const handleDebitsChange = (val, index) => {
    accountingData.debits[index].shiwakeValue = Number(val == "00" ? 0 : val)
    accountingData.debits[index].value = Number(val == "00" ? 0 : val)
    setAccountingData({ ...accountingData })
  }
  function getSalesRate() {
    let tempSalesRate = 0
    let tempDispensingAccounts = 0
    accountingData?.debits?.forEach((row) => {
      if (row.screenDisplay === "1") {
        if (row.key === "売掛金　調剤保険請求") {
          tempDispensingAccounts = Number(row.shiwakeValue == "00" ? 0 : row.shiwakeValue)
        }
      }

    })
    accountingData?.lenders?.forEach((tmp) => {
      if (tmp.key == "売上高（標準税率）") {
        tempSalesRate = tempDispensingAccounts + tmp.shiwakeValue
      }
    })
    return tempSalesRate
  }

  return (
    <Box>
      {isLoading && <BaseCircularProgress />}
      <div
        style={{
          margin: "24px",
          fontFamily: "Noto Sans JP",
          fontStyle: "Bold",
          fontWeight: 700,
          fontSize: "24px",
          lineHeight: "150%",
          color: "#162337",
        }}
      >
        {accountingData?.storeName}
        <Button
          variant="outlined"
          onClick={toDetail}
          color="orange"
          className={classes.rightButton}
        >
          日報内容の確認
        </Button>
      </div>

      {sumLenders - sumDebits !== 0 &&
        <Alert severity="error" className={classes.alert}>
          借方金額と貸方金額が異なります。
        </Alert>
      }
      <Table style={{ margin: "16px 24px", width: "calc(100% - 48px)" }}>
        <TableHead>
          <TableRow>
            <StyledTableCell align="left" style={{ width: "30%" }}>
              現金調整額
            </StyledTableCell>
            <TableCell
              align="right"
              style={{
                padding: "8px",
                paddingBottom: "7px",
                width: "70%",
                border: "1px solid #CCCCCC",
              }}
            >
              <NumberFormat
                value={cashAdjustment}
                className={cashAdjustment > 0 ? classes.inputContent : classes.inputContentEx}
                displayType={"text"}
                thousandSeparator={true}
              />
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          marginTop: "24px",
        }}
      >
        <div
          style={{
            width: "46.077%",
            height: "3.571%",
            marginLeft: "2.2%",
          }}
        >
          <div>
            <Typography className={classes.contentFont}>借方合計</Typography>
          </div>
          <div>
            <Typography
              className={classes.contentFont}
              style={{
                fontFamily: "Noto Sans JP",
                fontSize: "28px",
                lineHeight: "100%",
                borderBottom: "1px solid #CCCCCC",
                paddingBottom: "3px",
                textAlign: "right",
              }}
            >
              <NumberFormat
                value={sumDebits}
                displayType={"text"}
                thousandSeparator={true}
              />
              <span style={{ fontSize: "16px" }}>{`\u00A0\u00A0円`}</span>
            </Typography>
          </div>
        </div>
        <div
          style={{
            width: "46.4%",
            height: "3.571%",
            marginLeft: "2.7%",
          }}
        >
          <div>
            <Typography className={classes.contentFont}>貸方合計</Typography>
          </div>
          <div>
            <Typography
              className={classes.contentFont}
              style={{
                fontFamily: "Noto Sans JP",
                fontSize: "28px",
                lineHeight: "100%",
                borderBottom: "1px solid #CCCCCC",
                paddingBottom: "3px",
                textAlign: "right",
              }}
            >
              <NumberFormat
                value={sumLenders}
                displayType={"text"}
                thousandSeparator={true}
              />
              <span style={{ fontSize: "16px" }}>{`\u00A0\u00A0円`}</span>
            </Typography>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <TableContainer
          style={{
            margin: "24px",
            marginRight: "0px",
            width: "calc(50% - 36px)",
            height: "calc(100vh - 460px)",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell
                  align="center"
                  className={classes.headRow}
                  style={{ width: "50%" }}
                >
                  内訳
                </StyledTableCell>
                <StyledTableCell
                  className={classes.headRow}
                  align="center"
                  style={{ width: "50%" }}
                >
                  金額
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accountingData?.debits?.map((rows, index) => (
                rows.screenDisplay === "1" &&
                <TableRow key={index}>
                  <StyledTableCell align="left" className={classes.cell} style={{ borderLeft: "1px solid #CCCCCC" }}>
                    {rows.screenDisplayItem}
                  </StyledTableCell>
                  <StyledTableCell align="right" className={classes.cell}>
                    {rows.input === "1" ? rows.screenDisplayItem === "売掛金　調剤保険請求" ?
                      <div>
                        <BasicTextfield style={{ width: '100%' }}
                          value={rows.shiwakeValue}
                          autoComplete="off"
                          onFocus={function (e) {
                            var val = e.target.value;
                            e.target.value = '';
                            e.target.value = val;
                          }}
                          onChange={(event) => { event.target.value !== '-' && handleDebitsChange(event.target.value, index,) }}
                          name="numberformat"
                          id="formatted-numberformat-input"
                          inputProps={{
                            maxLength: rows.shiwakeValue > 0 ? 11 : parseInt(rows.shiwakeValue) === 0 ? 2 : 12,
                          }}
                          InputProps={{
                            placeholder: "金額",
                            classes: {
                              input: Number(rows.shiwakeValue) < 0 ? classes.inputContentEx : classes.inputContent,
                            },
                            inputComponent: NumberFormatCustom,
                          }}
                        />
                        <div style={{ height: 10 }}></div>
                        <NumberFormat value={accountingData?.dispensingCredit ?? 0} displayType={'text'} className={Number(accountingData?.dispensingCredit ?? 0) >= 0 ? classes.inputContent : classes.inputContentEx}
                          thousandSeparator={true} />
                      </div>
                      :
                      <BasicTextfield style={{ width: '100%' }}
                        value={rows.shiwakeValue}
                        autoComplete="off"
                        onFocus={function (e) {
                          var val = e.target.value;
                          e.target.value = '';
                          e.target.value = val;
                        }}
                        onChange={(event) => { event.target.value !== '-' && handleDebitsChange(event.target.value, index,) }}
                        name="numberformat"
                        id="formatted-numberformat-input"
                        inputProps={{
                          maxLength: rows.shiwakeValue > 0 ? 11 : parseInt(rows.shiwakeValue) === 0 ? 2 : 12,
                        }}
                        InputProps={{
                          placeholder: "金額",
                          classes: {
                            input: Number(rows.shiwakeValue) < 0 ? classes.inputContentEx : classes.inputContent,
                          },
                          inputComponent: NumberFormatCustom,
                        }}
                      />
                      : <NumberFormat value={rows.shiwakeValue} displayType={'text'} className={Number(rows.shiwakeValue) >= 0 ? classes.inputContent : classes.inputContentEx}
                        thousandSeparator={true} />}
                  </StyledTableCell>
                </TableRow>

              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer
          style={{
            margin: "24px",
            width: "calc(50% - 36px)",
            height: "calc(100vh - 460px)",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell
                  align="center"
                  className={classes.headRow}
                  style={{ width: "50%" }}
                >
                  内訳
                </StyledTableCell>
                <StyledTableCell
                  className={classes.headRow}
                  align="center"
                  style={{ width: "50%" }}
                >
                  金額
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accountingData?.lenders?.map((rows, index) => (
                rows.screenDisplay === "1" &&
                <TableRow key={index}>
                  <StyledTableCell align="left" className={classes.cell} style={{ borderLeft: "1px solid #CCCCCC" }}>
                    {rows.screenDisplayItem}
                  </StyledTableCell>
                  <StyledTableCell align="right" className={classes.cell}>
                    <NumberFormat
                      value={rows.key == "売上高（標準税率）" ? getSalesRate() : rows.shiwakeValue}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          onClick={() => {
            authorizeReport()
          }}
          color="orange"
          className={accountingData?.approvalStatus === "1" ? classes.authoirzeButton : classes.revokeAuthorizeButton}
        >
          {accountingData?.approvalStatus === "1" ? "この店舗を承認" : " この店舗を解除"}
        </Button>
      </div>
    </Box>
  );
}

// 画面エクスポート
export default withStyles(styles, { withTheme: true })(CompareBorrow);
