import axios from "axios";
import dealReturn from "./dealReturn";
import devConfig from "./config";
import { RouterString, networkErr, localConstants, Error } from "../global/common/constants";

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// axios.interceptors.request.use(
//     async config => {
//         config.headers["x-apigw-api-id"] = "bmmzo9nkbf"
//         return config;
//     },
//     error => {
//         console.error(error);
//     }
// );
axios.interceptors.response.use(
    response => {
        if (!response.status) { // Temporary solutions
            window.location.reload()
        }
        if (response.status == 200) {
            return response
        }
        return dealReturn(response);
    },
    error => {
        if (error.message == networkErr.ERR_INTERNET_DISCONNECTED || error.code == 'ERR_BAD_RESPONSE') {
            alert(Error.E0003)
            return Promise.reject(error);
        }
        return dealReturn(error.response);
    }
);

const createBaseUrl = (() => {
    const { host, baseUrl } = devConfig;
    return host + baseUrl;
})();

const request = (method, url, param) => {
    let temp = {
        baseURL: createBaseUrl,
        timeout: 30 * 1000,
        withCredentials: false,
        method,
        url
    };
    if (param) {
        temp = Object.assign(temp, param);
    }
    return axios.request(temp);
};

export default {
    get: (url, data) => {
        let urlParam = ""
        if (!data || JSON.stringify(data) == "{}" || !data.data || JSON.stringify(data.data) == "{}") {

        } else {
            for (let key in data.data) {
                if (key) {
                    if (!urlParam) {
                        urlParam = "?" + key + "=" + data.data[key]
                    } else {
                        urlParam = urlParam + "&" + key + "=" + data.data[key]
                    }
                }
            }
            url = url + urlParam
        }
        return request("get", url, data)

    },
    post: (url, data) => request("post", url, data),
    put: (url, data) => request("put", url, data),
    delete: (url, data) => request("delete", url, data),
    patch: (url, data) => request("patch", url, data)
};
